import React, { useState, useEffect } from 'react';
// Fetches the employee status from the server
const fetchEmployeeStatus = async (employeeId) => {
    const response = await fetch(`https://arjobcoach.com/get_status/${employeeId}`);
    const data = await response.json();
    return data;
};
const EmployeeStatus = ({ roomName }) => {
    const [status, setStatus] = useState(null);
    useEffect(() => {
        const employeeId = roomName.replace('employee', 'employee_');
        console.log(`Fetching status for: ${employeeId}`);
        fetchEmployeeStatus(employeeId).then(statusData => {
            console.log('Status data:', statusData);
            setStatus(statusData);
        });
    }, [roomName]);
    if (!status) {
        return null;
    }
    return (React.createElement("div", { className: "employee-status", style: { textAlign: 'center', padding: '10px', backgroundColor: 'lightgray' } },
        React.createElement("p", null,
            "Employee ",
            status.employeeId,
            " is currently: ",
            status.status ? 'Active' : 'Inactive')));
};
export default EmployeeStatus;
