import React, { useState, useEffect } from 'react';
import { makeStyles } from 'tss-react/mui';
import { withPixelLineHeight } from '../../../base/styles/functions.web';
const useStyles = makeStyles()(theme => {
    return {
        timer: {
            ...withPixelLineHeight(theme.typography.labelRegular),
            color: theme.palette.text01,
            padding: '6px 8px',
            backgroundColor: 'rgba(0, 0, 0, 0.8)',
            boxSizing: 'border-box',
            height: '28px',
            borderRadius: `0 ${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0`,
            marginRight: '2px',
            '@media (max-width: 300px)': {
                display: 'none'
            }
        }
    };
});
/**
 * Returns web element to be rendered.
 *
 * @returns {ReactElement}
 */
export default function ConferenceTimerDisplay({ textStyle: _textStyle }) {
    const { classes } = useStyles();
    const [seconds, setSeconds] = useState(45);
    const [color, setColor] = useState('red');
    useEffect(() => {
        const timer = setInterval(() => {
            setSeconds(prevSeconds => {
                if (prevSeconds === 1) {
                    setColor(prevColor => (prevColor === 'red' ? 'green' : 'red'));
                    return 45;
                }
                return prevSeconds - 1;
            });
        }, 1000);
        return () => clearInterval(timer);
    }, []);
    return (React.createElement("span", { className: classes.timer, style: { backgroundColor: color } }, seconds));
}
